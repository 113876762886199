jQuery(document).on("gform_post_render", function(
    event,
    form_id,
    current_page
) {
    jQuery('.ginput_container_fileupload > input[type="file"]').each(
        function() {
            const $input = jQuery(this);
            const defaultLabelText = "Choose file";

            const label = document.createElement("LABEL");
            label.classList.add("dropzone");
            label.dataset.filename = defaultLabelText;

            $input.wrap(label);

            const $label = $input.parent();

            $input.on("input", function(e) {
                const filePath = e.target.value;
                const fileName = filePath.substr(
                    filePath.lastIndexOf("\\") + 1
                );
                $label.attr("data-filename", fileName || defaultLabelText);
            });
        }
    );
});
