window.td_menu = function() {
    return {
        showMenu: false,
        openMenus: [],

        init() {           
            this.$watch("showMenu", (value) =>
                this.onMenuVisibilityChange(value)
            );

            this.addCloseMenuOnItemClickListener();
        },

        /**
         * All menu items starting with # will close the mobile menu when clicked
         *
         * @return  {void}
         */
        addCloseMenuOnItemClickListener(){
            let idMenuItems = document.querySelectorAll('header.-primary a[href^="#"]');

            for (const item of idMenuItems) {
                item.addEventListener('click', e => {
                    if(this.isOnMobile()){
                        this.showMenu = false;
                    }
                });            
            }
        },

        /**
         * Toggle a sub menu
         *
         * @param   {int}  menuItemID  Menu item ID
         * @param   {int}  parentID    Parent menu item ID
         *
         * @return  {void}
         */
        toggleMenu(menuItemID, parentID) {
            if (!this.isMenuOpen(menuItemID)) {
                if (parentID == 0) {
                    this.closeAllMenus();
                }

                this.openMenus = this.openMenus.concat({
                    id: menuItemID,
                    parent: parentID,
                });

                return;
            }

            // Close the current menu item and it's children
            this.closeMenu(menuItemID);
        },

        /**
         * Close all submenus
         *
         * @return  {void}
         */
        closeAllMenus() {
            this.openMenus = [];
        },

        /**
         * Close a submenu
         *
         * @param   {int}  menuItemID  Menu item ID
         *
         * @return  {void}
         */
        closeMenu(menuItemID) {
            this.openMenus = this.openMenus.filter((openMenuItem) => {
                return (
                    openMenuItem.parent != menuItemID &&
                    openMenuItem.id != menuItemID
                );
            });
        },

        /**
         * Is a submenu open?
         *
         * @param   {void}  menuID  Menu item ID
         *
         * @return  {boolean}       True if the menu is open, else false
         */
        isMenuOpen(menuID) {
            return this.openMenus.some(
                (openMenuItem) => openMenuItem.id === menuID
            );
        },

        /**
         * Handler for when a user clicks away from the header
         *
         * @param   {event}  e  The event
         *
         * @return  {void}
         */
        onClickAway(e) {
            if (!e.target.classList.contains("dropdown")) {
                this.closeAllMenus();
            }
        },

        /**
         * Handler for when the Esc key is pressend
         *
         * @param   {int}  menuItemID  ID of menu item we are currently on
         *
         * @return  {void}
         */
        closeOnEscape(menuItemID) {
            this.closeMenu(menuItemID);

            // Focus on top level parent
            let parentItem = document.querySelector(".dropdown-" + menuItemID);
            if (parentItem) {
                parentItem.focus();
            }
        },

        /**
         * Handler for when tab is pressed
         *
         * @return  {void}
         */
        onTab() {
            let parentMenu = $(document.activeElement).closest(".sub-menu");

            if (!parentMenu.length) {
                this.closeAllMenus();
            }
        },

        /**
         * Check if we are showing the mobile menu
         *
         * @return  {void}
         */
        isOnMobile() {
            return window.innerWidth < 1050;
        },

        /**
         * Handler for when the visibility of the menu changes
         *
         * @param   {boolean}  isVisible  Is the menu visible?
         *
         * @return  {void}
         */
        onMenuVisibilityChange(isVisible) {
            if (!this.isOnMobile()) {
                return;
            }

            if (isVisible) {
                document.body.classList.add("-no-scroll");
            } else {
                document.body.classList.remove("-no-scroll");
            }
        },
    };
};
