import { format, parse } from "date-fns";
import {
	findGetParameter,
	setGetParameter,
	removeGetParameter,
} from "../common/utilities";

window.td_case_studies = function () {
	return {
		posts: [],
		categories: [],
		currentCategory: "", // Use string for slugs
		currentPageNumber: 1,
		isLoading: true,
		numberOfPosts: 0,
		perPage: 9,
		totalPages: 0,

		fetchPosts() {
			this.posts = [];
			this.isLoading = true;

			let baseURL = "/wp-json/wp/v2/case-study?per_page=" + this.perPage;

			let currentCategory = findGetParameter("project_category"); // Get slug from URL

			if (currentCategory) {
				this.currentCategory = currentCategory;
				baseURL +=
					"&category_name=" + encodeURIComponent(currentCategory); // Use category_name for filtering by slug
			} else {
				this.currentCategory = ""; // Reset if no category is selected
			}

			baseURL += "&page=" + this.currentPageNumber;
			baseURL += "&_embed"; // Add media to response

			return fetch(baseURL)
				.then((response) => {
					let totalPages = parseInt(
						response.headers.get("X-WP-TotalPages")
					);

					if (!isNaN(totalPages)) {
						this.totalPages = totalPages;
					}

					return response.json();
				})
				.then((posts) => {
					this.posts = this.formatPosts(posts);
					this.isLoading = false;
				});
		},

		formatPosts(posts) {
			for (let post of posts) {
				let date = new Date(post.date);
				post.formattedDate = format(date, "do LLLL, yyyy");

				// Check if featured media exists
				if (
					post._embedded &&
					post._embedded["wp:featuredmedia"] &&
					post._embedded["wp:featuredmedia"][0]
				) {
					let image = post._embedded["wp:featuredmedia"][0];
					let imageSizes = image["media_details"];

					post.featuredImageURL = image.source_url;

					if (
						imageSizes &&
						imageSizes["sizes"] &&
						imageSizes["sizes"]["large"]
					) {
						post.featuredImageURL =
							imageSizes["sizes"]["large"].source_url;
					}
				} else {
					// Fallback image if no featured image exists
					post.featuredImageURL =
						"data:image/svg+xml,%3Csvg viewBox='0 0 771 771' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%236bc9a5' d='M385.473 0C598.412 0 771 172.592 771 385.466C771 598.394 598.426 771 385.473 771C172.519 771 0 598.394 0 385.466C0 172.592 172.533 0 385.473 0M399.742 153.329L264.778 347.067C258.44 356.155 256.427 367.528 259.247 378.221L319.459 606.188C326.702 633.654 362.996 640.008 379.261 616.676L514.224 422.938C520.549 413.851 522.576 402.478 519.755 391.798L459.53 163.818C452.287 136.351 415.993 129.985 399.742 153.329' fill-rule='evenodd'/%3E%3C/svg%3E";
				}

				// Add category slug instead of ID
				if (post._embedded && post._embedded["wp:term"]) {
					let categories = post._embedded["wp:term"].find(
						(terms) => terms[0].taxonomy === "project_category"
					);
					if (categories && categories.length) {
						post.category = categories[0].name; // Keep category name
						post.categorySlug = categories[0].slug; // Add category slug
					}
				} else {
					post.category = "Uncategorized"; // Fallback if no category is found
					post.categorySlug = "";
				}
			}

			return posts;
		},

		nextPage() {
			if (this.currentPageNumber < this.totalPages) {
				this.currentPageNumber++;
			}

			this.$nextTick(() => this.scrollToTop());

			this.fetchPosts();
		},

		previousPage() {
			if (this.currentPageNumber > 1) {
				this.currentPageNumber--;
			}

			this.$nextTick(() => this.scrollToTop());

			this.fetchPosts();
		},

		goToPage(page) {
			if (page !== this.currentPageNumber) {
				this.currentPageNumber = page;
				this.fetchPosts();
			}
		},

		showCategory(slug) {
			this.currentPageNumber = 1;
			this.currentCategory = slug; // Store slug instead of ID

			if (slug) {
				setGetParameter("project_category", slug); // Set slug in URL
			} else {
				removeGetParameter("project_category"); // Remove category from URL if empty
			}

			this.fetchPosts();
		},

		scrollToTop() {
			$(document).scrollTop(0);
		},
	};
};
