const observer = new IntersectionObserver(
    (entries, observer) => {
        entries.forEach(({ target, isIntersecting, boundingClientRect }) => {
            const isNegativeTop = boundingClientRect.top <= 0;
            const isVisible = !!target.offsetParent;

            if (isVisible) {
                if (!isIntersecting && isNegativeTop) {
                    target.classList.add("-downwards");
                } else if (!isIntersecting) {
                    target.classList.remove("-downwards");
                }
            } else {
                target.classList.remove("-downwards");
            }
        });
    },
    { threshold: 1 }
);

document.querySelectorAll(".sub-menu").forEach((target) => {
    observer.observe(target);
});
