import debounce from 'debounce';

class UpdateVariables {
    constructor(){
        this.addResizeHandler();
    }

    addResizeHandler(){
        window.addEventListener('scroll', debounce(this.updateScrollVariable, 16));
    }

    updateScrollVariable() {
        document.documentElement.style.setProperty('--scroll-position', Math.round(window.scrollY));
    }
}

export default UpdateVariables;