const map = {
    "consult-text": "consult",
    "digital-product-design-text": "design",
    "custom-web-app-builds-text": "build",
    "optimise-text": "optimise",
};

const observerOptions = {
    threshold: 1,
};

const observerCallback = (entries, observer) => {
    entries.forEach((entry) => {
        const svgPath = document.getElementById(map[entry.target.id]);
        svgPath.classList.toggle("pulse", entry.isIntersecting);
    });
};

const observer = new IntersectionObserver(observerCallback, observerOptions);

Object.keys(map).forEach((id) => {
    const el = document.getElementById(id);
    if (el) {
        observer.observe(el);
    }
});
