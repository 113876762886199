export default class HomepageValues {
    constructor() {
        this.block = document.querySelector('.values-block');

        if (!this.block) {
            return;
        }

        this.headings = this.block.querySelectorAll('.value');

        this.observer = new IntersectionObserver(this.handle.bind(this));
        this.observer.observe(this.block);

        this.addDelays(this.headings);
    }
    handle(entries) {
        entries.forEach(({ isIntersecting }) => {
            this.addClass(isIntersecting);
        });
    }
    addClass(isIntersecting) {
        if(isIntersecting){
            this.block.classList.add('-animate');
        }
    }
    addDelays(headings) {
        headings.forEach((heading, i) => {
            heading.style.setProperty('--value-delay', `${i+1}s`);
        });
    }
}
