export function findGetParameter(parameterName) {
    var result = null;
    var tmp = [];
    var items = location.search.substr(1).split("&");
    for (var index = 0; index < items.length; index++) {
        tmp = items[index].split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }
    return result;
}

export function setGetParameter(name, value) {
    window.history.replaceState(null, null, `?${name}=${value}`);
}

export function removeGetParameter(name) {
    const url = new URL(window.location);
    url.searchParams.delete(name);
    window.history.replaceState(null, null, url)
}