class AddClassAfterDelay{
    constructor(selector, classToAdd, delay) {
        if(!document.querySelector(selector)){
            return;
        }

        setTimeout(() => {
            let element = document.querySelector(selector);

            element.classList.add(classToAdd);

        }, delay);
    }
}

export default AddClassAfterDelay;